.day-meta {
  padding: 5px 0;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.day-meta-wrapper {
  font-size: 15px;
  line-height: normal;
  height: 24px;
  border-radius: 12px;
  color: var(--message-service-color);
  background: var(--message-service-background);
  padding: 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
