.messages-list {
  box-sizing: border-box;
  flex: 1 1 0;
  display: flex;
  position: relative;
  overflow: hidden;
  /*overflow-y: auto;*/
  /*-webkit-overflow-scrolling: touch;*/
}

.messages-list-wrapper {
  box-sizing: border-box;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  transform: translateZ(0);
  will-change: transform;
  padding: 0 6px 0 6px;
  /* scroll-behavior: smooth; */
  /*mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 1) 15px);*/
}

.messages-list-top {
  flex: 1 1 auto;
  min-height: 12px;
  position: relative;
}

.messages-list-items {
  flex: 0 0 auto;
  padding-bottom: 4px;
  /* padding-top: 55px; */
}

.messages-list-top-panel {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}
