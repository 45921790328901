.prmote {
  padding: 30px 30px;
  max-width: 1000px;
  margin: 0 auto;
  color: #926a6a;
  overflow-y: scroll;
}
.title {
  font-size: 36px;
}
.desc {
  font-weight: bold;
  font-size: 24px;
  margin-top: 20px;
  line-height: 36px;
}
.slogan {
  font-size: 18px;
  margin-top: 10px;
  /* font-weight: bold; */
}

.introimg {
  /* float: right; */
  margin-top: 20px;
  width: 300px;
}
hr {
  font-size: 12.6px;
  height: 6px;
  width: 140px;
  background-color: #f65d2a;
  border: none;
  margin: 10px 0 0 0;
}

.start-chat-btn {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}
