.meta {
  user-select: none;
  font-size: 10px;
  line-height: 14px;
  flex-shrink: 0;
  color: var(--message-in-meta-color);
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-left: 3px;
}

.meta-text {
  position: absolute;
  right: 4px;
  bottom: 3px;
}

.message:not(.message-out) .meta-text:not(.meta-bubble) {
  right: 7px;
}

.meta-bubble {
  height: 18px;
  align-items: center;
  border-radius: 9px;
  padding: 0 5px 0 0;
  background: rgba(0, 0, 0, 0.25);
}

.message .meta.meta-bubble,
.message .meta.meta-bubble a {
  color: var(--message-service-color);
}

.meta-hidden {
  opacity: 0;
  display: inline-block;
}

.message .meta a {
  color: var(--message-in-meta-color);
}

.message-out .meta {
  color: var(--message-out-meta-color);
  fill: var(--message-out-meta-color);
}

.message-out .meta a {
  color: var(--message-out-meta-color);
}

.meta-views {
}

.meta-views-icon {
  font-size: 10px;
  /*transform: translateY(2px);*/
}

.meta-pin-icon {
  font-size: 12px;
  transform: rotate(45deg) translateY(2px);
}

.meta-image {
  color: white !important;
  fill: white !important;
  background-color: #00000091;
  padding: 3px 6px 3px 6px;
  border-radius: 10px;
}
