.header-wrapper {
  /* border-style: solid; */
  border-width: 0px 0px 1px 0px;
  border-color: rgb(223, 223, 223);
  background-color: var(--theme-window-bgcolor);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 8px 4px 8px;
  flex-direction: row;
  user-select: none;

  /* position: fixed;
  width: 100%;
  top: 0px;
  z-index: 9999; */
}

.chat-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
  display: block;
  width: 200px;
}
