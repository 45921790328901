.discover-panel-wrapper {
  height: 100%;
  /* padding: 20px; */
  /* background-image: var(--theme-bg-match); */
  -webkit-touch-callout: none;
  background-size: 100%;
  position: relative;
}

.update-logs-item {
}

.update-logs-item span {
  display: block;
  margin: 4px 0px;
}

.update-logs-more {
  margin-top: 20px;
}

.update-logs-more .adm-list-item-content-main {
  padding: 0;
}

.match-wrapper {
  box-sizing: border-box;
  /* position: absolute;
  bottom: 0px; */
  z-index: 100;
  padding: 20px 20px 0px 20px;
  width: 100%;
  box-sizing: border-box;
  background: var(--theme-page-bgcolor);
}
.line1 {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 160px;
}
.bottle-wrapper {
  width: 43%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 10px;
}
.bottle {
  cursor: pointer;
  border-radius: 12px;
  width: 100%;
  color: white;
  flex: 1;
  font-size: 18px;
  box-sizing: border-box;
  padding: 10px 10px;
}
.bottle1 {
  background: linear-gradient(to right, rgb(100, 112, 254), rgb(158, 96, 254));
}
.bottle2 {
  background: linear-gradient(to right, rgb(10, 183, 190), rgb(44, 168, 206));
}
.right-wrapper {
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 10px;
}
.mattch-btn {
  cursor: pointer;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #ff5f92, #feb47b);
  padding: 10px;
  box-sizing: border-box;
  color: white;
}
.mattch-btn .t1 {
  font-size: 18px;
  margin-bottom: 2px;
}
.mattch-btn .t2 {
  font-size: 12px;
}
.meet-btn {
  cursor: pointer;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #1f52d4, #54a6d5);
  padding: 10px;
  box-sizing: border-box;
  color: white;
}
.meet-btn .t1 {
  font-size: 18px;
  margin-bottom: 2px;
}
.meet-btn .t2 {
  font-size: 12px;
}
.setting-wrapper {
  position: absolute;
  top: -6px;
  right: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.setting-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  width: 90px;
  height: 36px;
  /* background: linear-gradient(to right, #2c4af4, #4a56b1); */
  /* background-color: #1677ff; */
  background-color: var(--theme-page-bgcolor);
  border: 1px solid #1677ff;
  box-sizing: border-box;
  font-size: 14px;
  color: #1677ff;
}
.ward-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}
.ward-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  width: 80px;
  height: 36px;
  /* background: linear-gradient(to right, #2c4af4, #4a56b1); */
  /* background-color: #1677ff; */
  background-color: var(--theme-page-bgcolor);
  border: 1px solid #019647;
  box-sizing: border-box;
  font-size: 14px;
  color: #019647;
}
