.discover-panel-wrapper {
  height: 100%;
  /* padding: 20px; */
  /* background-image: var(--theme-bg-match); */
  -webkit-touch-callout: none;
  background-size: 100%;
  position: relative;
}

.update-logs-item {
}

.update-logs-item span {
  display: block;
  margin: 4px 0px;
}

.update-logs-more {
  margin-top: 20px;
}

.update-logs-more .adm-list-item-content-main {
  padding: 0;
}

.match-wrapper {
  box-sizing: border-box;
  /* position: absolute;
  bottom: 0px; */
  z-index: 100;
  padding: 20px 20px 0px 20px;
  width: 100%;
  box-sizing: border-box;
  background: var(--theme-page-bgcolor);
}
.line1 {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 160px;
}
.bottle-wrapper {
  width: 43%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 10px;
}
.bottle {
  cursor: pointer;
  border-radius: 12px;
  width: 100%;
  color: white;
  flex: 1;
  font-size: 18px;
  box-sizing: border-box;
  padding: 10px 10px;
}
.bottle1 {
  background: linear-gradient(to right, rgb(100, 112, 254), rgb(158, 96, 254));
}
.bottle2 {
  background: linear-gradient(to right, rgb(10, 183, 190), rgb(44, 168, 206));
}
.right-wrapper {
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 10px;
}
.mattch-btn {
  cursor: pointer;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #ff5f92, #feb47b);
  padding: 10px;
  box-sizing: border-box;
  color: white;
}
.mattch-btn .t1 {
  font-size: 18px;
  margin-bottom: 2px;
}
.mattch-btn .t2 {
  font-size: 12px;
}
.meet-btn {
  cursor: pointer;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #1f52d4, #54a6d5);
  padding: 10px;
  box-sizing: border-box;
  color: white;
}
.meet-btn .t1 {
  font-size: 18px;
  margin-bottom: 2px;
}
.meet-btn .t2 {
  font-size: 12px;
}
.setting-wrapper {
  position: absolute;
  top: -6px;
  right: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.setting-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  width: 90px;
  height: 36px;
  /* background: linear-gradient(to right, #2c4af4, #4a56b1); */
  /* background-color: #1677ff; */
  background-color: var(--theme-page-bgcolor);
  border: 1px solid #1677ff;
  box-sizing: border-box;
  font-size: 14px;
  color: #1677ff;
}
.ward-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}
.ward-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  width: 80px;
  height: 36px;
  /* background: linear-gradient(to right, #2c4af4, #4a56b1); */
  /* background-color: #1677ff; */
  background-color: var(--theme-page-bgcolor);
  border: 1px solid #019647;
  box-sizing: border-box;
  font-size: 14px;
  color: #019647;
}

.swiper-item-wrapper {
  height: 100%;
}

.notice-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 60px;
  height: 30px;
  background-color: var(--theme-page-bgcolor);
  box-sizing: border-box;
  font-size: 14px;
  /* color: #fff; */
}
.claim-notice-btn {
  border: 1px solid #ff4916 !important;
  color: #ff4916 !important;
  /* background-color: #ff7f00e8; */
}
.push-notice-btn {
  border: 1px solid #ff8100 !important;
  color: #ff8100 !important;
  /* background-color: #1677ffde; */
}

.swiper-wrapper {
  /* box-sizing: border-box;
  padding: 30px 0px 0px 30px; */
  height: calc(100vh - 460px);
  max-height: 160px;
  background: var(--lost-bg);
  padding: 12px 20px 0px 20px;
  border-radius: 8px;
  margin: 30px 20px 0px 20px;
  box-sizing: border-box;
}

.lost-item-wrapper {
  height: calc(100% - 20px);

  /* background-color: var(--theme-page-bgcolor); */
  /* border: 1px solid var(--lost-border); */
  /* background-image: linear-gradient(to bottom, #a8edea 0%, #fed6e3 100%); */
  /* color: var(--lost-color); */
}

.lost-item-title {
  width: 100%;
  /* text-align: center; */
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 6px;
  color: var(--theme-text-color);
}

.lost-item-content {
  font-size: 14px;
  letter-spacing: 1px;
  color: var(--theme-text-color);
  margin-bottom: 20px;
}
.nodata {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: var(--theme-text-color);
}

.save-lost-title {
  font-size: 18px;
  margin: 10px 0px;
  text-align: center;
  font-weight: bold;
  color: var(--theme-text-color);
}
.lost-item {
  margin: 4px;
  color: var(--theme-text-color);
}
.lost-label {
}
.lost-value {
}
.lost-question {
  font-size: 16px;
  margin-top: 20px;
  font-weight: bold;
}
