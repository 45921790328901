.item {
  margin-bottom: 20px;
}

.question-no {
  width: 40px;
  display: inline-block;
  position: absolute;
  top: 0;
}

.question-wrap {
  font-size: 18px;
  margin-bottom: 6px;
  position: relative;
  user-select: none;
  /* display: flex; */
}

.question {
  display: inline-block;
  width: calc(100% - 40px);
  margin-left: 40px;
}

.answer-options {
  font-size: 16px;
  /* color: var(--adm-color-weak); */
  margin-left: 40px;
}

.radio {
  --icon-size: 18px;
  --font-size: 16px;
  --gap: 6px;
}
