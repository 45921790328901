.placeholder-meta {
  user-select: none;
  color: var(--message-service-color);
  background: var(--message-service-background);
  border-radius: 12px;
  padding: 3px 12px 3px 12px;
  font-size: 14px;
}

.placeholder-meta-wrapper {
  /* display: flex;
  justify-content: center; */
  /* width: 100%; */
  padding: 80px;
}
