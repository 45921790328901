.set-item-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid #d9d9d9;
}

.helpitem {
  --extra-max-width: 80% !important;
}
