html {
  height: 100%;
  width: 100%;
  touch-action: manipulation;
  /* overflow: hidden; */
}

@font-face {
  font-family: 'MiSans-Normal';
  src: url('../public/font/MiSans-Normal.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'MiSans-Normal';
  /* overflow: hidden; */
  /* background-color: var(--theme-window-bgcolor); */
  background-image: var(--theme-bg);
  -webkit-touch-callout: none;
  background-size: 100% 100%;
  background-attachment: fixed;

  scrollbar-color: rgba(0, 0, 0, 0.25) transparent;
  scrollbar-width: thin;

  width: 100vw;
  width: var(--app-width);

  height: 100vh;
  height: var(--app-height);

  margin: auto;
}

img {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* input,
textarea {
  font-family: 'Segoe UI Emoji', 'Segoe UI Symbol', 'Segoe UI', 'Apple Color Emoji', 'Twemoji Mozilla',
    'Noto Color Emoji', 'Android Emoji';
} */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
  /*border: 1px solid transparent;*/
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  /*border: 3px solid transparent;*/
  /*min-height: 50px;*/
  /*height: 50px;*/
  /*max-height: 50px;*/
}

.adm-notice-bar {
  /* border: solid 1px var(--broadcast-bg-color) !important; */
  border: 0px !important;
  background-color: var(--broadcast-bg-color) !important;
  color: var(--broadcast-text-color) !important;
}
