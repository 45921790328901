.topic-wrapper {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--message-service-color);

  min-height: 24px;
  font-size: 17px;
  line-height: normal;
  padding: 6px;
  /* margin-bottom: 10px; */
}

.topic {
  background: var(--message-service-background);
  border-radius: 12px;
  padding: 6px 12px;
  text-align: center;
}
