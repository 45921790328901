.info-item-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.info-item {
  display: flex;
  align-items: center;
}

.info-item .label {
  font-size: 16px;
  color: var(--theme-text-color);
  width: 80px;
}

.info-item .value {
  font-size: 16px;
  color: var(--text-secondary);
  padding: 6px;
  /* border: 1px solid #d9d9d9; */
  /* border-radius: 6px; */
  width: 60px;
  height: 23px;
  line-height: 23px;
}

.info-item .btn {
  float: right;
}
.login-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
