.dialog-wrapper {
  /* padding-top: 10px;
  padding-bottom: 10px; */

  height: 100%;

  /* border-style: solid;
  border-width: 0 1px 0 1px;
  border-color: rgb(223, 223, 223); */
}
