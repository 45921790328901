.dialog-list-item-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dialog-inner-wrapper {
  margin-left: 10px;
  overflow: hidden;
  z-index: 1;
  width: calc(100% - 60px);
}

.second-row {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dialog-title-wrapper {
  max-width: 240px;
}

.dialog-title-span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
  display: block;
  /* word-wrap: break-word; */
}
.is-ban {
  color: rgb(158, 158, 158);
  text-decoration: line-through;
}
.otherLeave {
  color: rgb(158, 158, 158);
}
