.match-set-wrapper {
  text-align: center;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.match-set-wrapper.disabled {
  background-color: #333;
  opacity: 0.5;
}

.match-set-item {
  display: flex;
  /* margin: 0 auto; */
  font-size: 12px;
  align-items: center;
}

.setting-label {
  width: 40px;
  text-align: left;
}

.match-set-wrapper .adm-dialog-content {
  padding: 0 12px 0px;
}
