.page-wrapper-outer {
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
  background-color: var(--theme-page-bgcolor);
  overflow: hidden;
}

.page-title {
  height: 20px;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  background-color: var(--theme-window-bgcolor);
  color: var(--theme-text-color);
  position: relative;
}
