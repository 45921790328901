.card {
  text-align: center;
  border: 1px solid #b8b8b8;
  border-radius: 10px;
  position: relative;
  /* box-shadow: 5px 5px 10px 5px #f0f0f0; */
  cursor: pointer;
}

.img {
  border-radius: 10px;
  width: 100%;
}

.time {
  font-size: 16px;
  margin: 0px 0px 4px 0px;
  color: var(--text-secondary);
}

.pay-wrapper {
  text-align: center;
  color: var(--text-secondary);
}
.pay-wrapper .item {
  margin: 0 4px;
}

.pay {
  font-size: 23px;
  color: red;
}

.origin-pay {
  font-size: 14px;
  text-decoration: line-through;
}

.description {
  font-size: 14px;
  /* font-weight: 800; */
}

.buy-btn {
  background: #409eff;
  border-radius: 6px;
  color: #fff;
  display: block;
  font-size: 18px;
  height: 35px;
  line-height: 35px;
  width: 120px;
  margin: 6px auto 12px;
  cursor: pointer;
}

.line {
  margin: 0 24px;
}

.buy {
  font-size: 28px;
  font-weight: 800;
  display: inline-block;
}

.card-wrapper {
  margin-top: 20px;
}

.col {
  vertical-align: middle !important;
}

.opt-item {
  color: #409eff;
  font-weight: bold;
}

.order-item {
  margin: 0 4px;
}
