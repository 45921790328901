.record-timer {
  display: flex;
  align-items: center;
}

.record-timer-icon {
  width: 10px;
  height: 10px;
  background: red;
  border-radius: 50%;
  margin: 0 18px;
  animation: blinker 0.5s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}

.Blink {
}

@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
