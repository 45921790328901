.inputbox-wrapper {
  /* background: rgb(141, 51, 51); */
  padding: 8px 0px 8px 0px;
  background-color: var(--theme-window-bgcolor);
}
.inputbox {
  /*padding: 0 12px;*/
  /*display: flex;*/
  /*flex-direction: column;*/
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  box-sizing: border-box;
  /*border-top-width: 1px;*/
  /*border-top-style: solid;*/
  /*border-color: var(--border);*/
  /* padding: 0 0 6px; */
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.inputbox::before {
  --shadow-color: #84a686;
  /* content: ''; */
  display: block;
  position: absolute;
  top: 0;
  left: -34px;
  right: 0;
  /* height: 50%; */
  box-shadow: 0 0 0.5rem 0.5rem var(--shadow-color);
  background: var(--shadow-color);
}

.dark .inputbox::before {
  --shadow-color: var(--chat-background) !important;
}

.inputbox-icon-button1 {
  padding: 6px;
  margin: 9px;
}

.inputbox-bubble-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 12px 12px 12px 12px;
  box-shadow: 0 1px 2px 0 rgba(16, 35, 47, 0.15);
  background: var(--theme-input-bgcolor);
  z-index: 1;
  max-width: calc(100% - 82px);
  caret-color: var(--theme-caret-color);
}

.inputbox1-bubble-wrapper::after {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 8px;
  height: 17px;
  content: '';
  mask: url(@/assets/bubble-tail-left.svg);
  transform: translateX(7px) scaleX(-1);
  box-sizing: border-box;
  background: var(--message-in-background);
}

.inputbox-bubble {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

#inputbox-message {
  box-shadow: 0 0 0 0;
  max-height: 150px;
  font-size: 15px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 10px 0px 10px 4px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
}

#inputbox-message::before {
  /*content: attr(placeholder);*/
  color: #8e9396;
  pointer-events: none;
  transform: translateX(20px);
  will-change: transform;
  opacity: 0;
  /*position: absolute;*/
  transition: opacity 0.15s ease-out, transform 0.15s ease-out;
}

#inputbox-message:empty::before {
  content: attr(placeholder);
  transform: translateX(0px);
  opacity: 0.75;
}

#inputbox-message:empty:active::before,
#inputbox-message:empty:focus::before {
  opacity: 0.75;
}

#inputbox-message:focus {
  border: 0;
  outline: none;
  box-shadow: 0 0 0 0;
}

.inputbox-attach-button {
  display: none;
}

.inputbox-left-column {
}

.inputbox-middle-column {
  padding-left: 6px;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
}

.inputbox-right-column {
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  align-items: center;
  min-height: 52px;
}

.inputbox-send-button {
  width: 100%;
  height: 100%;
  z-index: 1;
}

.inputbox-send-accent-button {
  color: var(--color-accent-main);
}

.inputbox-cancel-record-button {
  width: 100%;
  height: 100%;
  /*color: var(--color-);*/
  z-index: 1;
}

.inputbox-cancel-record-button-background {
  margin: 0 8px 0 0;
  min-width: 52px;
  height: 52px;
  border-radius: 50%;
  box-shadow: 0 1px 2px 0 rgba(16, 35, 47, 0.15);
  background: var(--panel-background);
  z-index: 1;
}

.inputbox-send-button-background {
  border-radius: 50%;
  box-shadow: 0 1px 2px 0 rgba(16, 35, 47, 0.15);
  background: var(--panel-background);
  background-color: #0288d1;
  z-index: 1;
}
